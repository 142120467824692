/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InvitePortalAdministratorUserRequest { 
    identifier: string;
    role: InvitePortalAdministratorUserRequest.RoleEnum;
}
export namespace InvitePortalAdministratorUserRequest {
    export type RoleEnum = 'admin_full' | 'admin_view';
    export const RoleEnum = {
        Full: 'admin_full' as RoleEnum,
        View: 'admin_view' as RoleEnum
    };
}


