/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PlaceOrderRequestVoucher } from './placeOrderRequestVoucher';


export interface PlaceOrderRequest { 
    method: PlaceOrderRequest.MethodEnum;
    voucher?: PlaceOrderRequestVoucher;
}
export namespace PlaceOrderRequest {
    export type MethodEnum = 'demo' | 'coinify' | 'card' | 'eft' | 'wallet' | 'voucher';
    export const MethodEnum = {
        Demo: 'demo' as MethodEnum,
        Coinify: 'coinify' as MethodEnum,
        Card: 'card' as MethodEnum,
        Eft: 'eft' as MethodEnum,
        Wallet: 'wallet' as MethodEnum,
        Voucher: 'voucher' as MethodEnum
    };
}


