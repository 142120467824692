/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserAccountBalanceChartRequest { 
    currency: string;
    range: UserAccountBalanceChartRequest.RangeEnum;
    customDate?: string;
}
export namespace UserAccountBalanceChartRequest {
    export type RangeEnum = 'one_day' | 'one_month' | 'one_year' | 'ytd' | 'all' | 'custom';
    export const RangeEnum = {
        OneDay: 'one_day' as RangeEnum,
        OneMonth: 'one_month' as RangeEnum,
        OneYear: 'one_year' as RangeEnum,
        Ytd: 'ytd' as RangeEnum,
        All: 'all' as RangeEnum,
        Custom: 'custom' as RangeEnum
    };
}


