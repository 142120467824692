/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AuditItem { 
    action: string;
    identifier: string;
    ipv4: string;
    ipv6: string;
    os: string;
    osVersion: string;
    userAgent: string;
    metadata: Array<string>;
    createdAt: string;
}

