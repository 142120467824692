/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PlaceOrderResponse { 
    status: PlaceOrderResponse.StatusEnum;
    message: string;
    redirectUrl?: string;
}
export namespace PlaceOrderResponse {
    export type StatusEnum = 'success' | 'failed' | 'pending' | 'kyc_required';
    export const StatusEnum = {
        Success: 'success' as StatusEnum,
        Failed: 'failed' as StatusEnum,
        Pending: 'pending' as StatusEnum,
        KycRequired: 'kyc_required' as StatusEnum
    };
}


